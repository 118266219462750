
  import { encodeOpts } from '@/utils/encode-opts';
  import ProductCardInfo from './productCard/ProductCardInfo.vue';
  import checkProductsPrices from '../plugins/check.products.prices';
  import { mapGetters } from 'vuex';

  export default {
    mixins: [checkProductsPrices],
    components: {
      ProductCardInfo,
      ProductsGridTemplate: () => import('./ProductsGridTemplate'),
    },
    data() {
      return {
        shipCheckPricesOnMount: true,
        loading: false,
        productsList: [],
      };
    },
    props: {
      products: Object,
      pagination: Object,
      category: Object,
    },
    computed: {
      ...mapGetters({
        storeInformation: 'storeInformation',
      }),
    },
    async mounted() {
      if (this.category) {
        try {
          const productsList = (
            await this.$axios.get(`/categories/${this.category._id}/products`, {
              params: {
                limit: parseInt(this.products.limit) || 24,
                page: this.currentPage || 1,
                storeId: this.storeInformation._id,
                filters: {
                  status: 'active',
                },
              },
              // paramsSerializer: encodeOpts
            })
          ).data.data;
          this.productsList = productsList;
          this.checkDecorationPrices();
        } catch (e) {}
      }
    },
  };
